import { observer } from 'mobx-react';
import { useLocalObservable } from 'mobx-react-lite';
import { createElement, forwardRef, useCallback } from 'react';

import PropTypes from 'prop-types';

import { Modal, makeStyles } from '@bequestinc/wui';
import { Alert, Slide, Step, StepContent, StepLabel, Stepper } from '@mui/material';

import { useGlobalContext } from 'hooks';
import { useFirmQuery } from 'hooks/query-hooks/firms/useFirmsQuery';

import { CONFIRM } from 'utils/constants';

import OfficeInfoForm from 'components/pages/firm-info/OfficeInfoForm';
import PlaceHolder from 'components/pages/home/PlaceHolder';

const useStyles = makeStyles()({
  stepper: {
    width: '60%',
    alignItems: 'flex-beginning',
    padding: 5,
  },
  alert: {
    width: '60%',
  },
});

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
Transition.displayName = 'Transition';

const ForceCheckInformationModal = ({ firmId, open, onClose }) => {
  const { firm } = useGlobalContext();
  const { classes } = useStyles();
  const store = useLocalObservable(() => ({
    currentStep: 0,
    steps: [],

    moveBack: () => store.currentStep--,

    moveForward: () => {
      store.currentStep++;
      if (store.currentStep === store.steps.length) {
        firm.setState({ ...firm, hasOfficesThatNeedForcedCheck: false });
        onClose();
      }
    },

    reset: () => {
      store.currentStep = 0;
      store.steps = [];
    },

    addOfficeInfoStep: office => {
      store.steps.push({
        label: `Confirm ${office.street}'s Information`,
        component: OfficeInfoForm,
        props: {
          office,
          canUpdate: true,
          action: CONFIRM,
          afterSubmitAction: store.moveForward,
        },
      });
    },
  }));

  const populateSteps = lawFirm => {
    if (store.steps.length) return;
    lawFirm.offices.forEach(office => {
      if (office.needsForcedCheck) store.addOfficeInfoStep(office);
    });
  };

  const { isFetching } = useFirmQuery(firmId, { select: useCallback(populateSteps, []) });

  if (isFetching) return <PlaceHolder alignToLeft />;

  return (
    <Modal
      title={`Confirm Firm Information (${store.currentStep + 1}/${store.steps.length})`}
      fullScreen
      open={open}
      onClose={() => {
        store.reset();
        onClose();
      }}
      TransitionComponent={Transition}
    >
      <>
        <Alert severity="warning" className={classes.alert}>
          Please confirm your data to ensure that we have accurate data to share with our members.
          Note that your profile will still be shown to our members so that they can still get in
          touch with you while you're working to confirm your data, but not confirming will result
          in poorer job and client matching.
        </Alert>
        <Stepper activeStep={store.currentStep} orientation="vertical" className={classes.stepper}>
          {store.steps.map(step => {
            return (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
                <StepContent>{createElement(step.component, step.props)}</StepContent>
              </Step>
            );
          })}
        </Stepper>
      </>
    </Modal>
  );
};

ForceCheckInformationModal.propTypes = {
  firmId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default observer(ForceCheckInformationModal);
