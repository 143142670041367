import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

import { getVisibleMemos, hasSubmittedMalpractice } from 'api';

import { Spacer, makeStyles } from '@bequestinc/wui';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useGlobalContext, useToggledState } from 'hooks';
import { useCurrentUserQuery } from 'hooks/query-hooks/users/useUserQuery';

import { ATTORNEY_MENU_ITEMS, CAN_EDIT_PLF_INFO } from 'utils/constants';

import ForceCheckInformationModal from 'components/ForceCheckInformationModal';
import MalpracticeUploadModal from 'components/MalpracticeUploadModal';
import ButtonGrid from 'components/pages/home/ButtonGrid';

const useStyles = makeStyles()(theme => ({
  bannerText: {
    backgroundColor: '#5f249f',
    color: theme.palette.common.white,
    textAlign: 'center',
    padding: '5px',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  linkText: {
    color: theme.palette.common.white,
  },
  disabledText: {
    backgroundColor: '#f5232f',
    '&:hover': {
      backgroundColor: '#f5232f',
    },
    color: theme.palette.common.white,
    fontSize: '15px',
    fontWeight: 'bold',
  },
}));

const AttorneyHome = () => {
  const { firm } = useGlobalContext();
  const [memos, setMemos] = useState([]);
  const [showMalpracticeWarning, setShowMalpracticeWarning] = useState(false);
  const [showMalpracticeModal, toggleShowMalpracticeModal] = useToggledState(false);
  const [showForceCheckInformationModal, toggleShowForceCheckInformationModal] =
    useToggledState(false);
  const { classes } = useStyles();

  const { data: user } = useCurrentUserQuery();

  useEffect(() => {
    getVisibleMemos()
      .then(response => setMemos(response.data))
      .catch(() => {
        // Don't error out if failed to fetch memos?
      });
    if (firm.malpracticeExpired) {
      hasSubmittedMalpractice().then(response => {
        if (!response.data) {
          setShowMalpracticeWarning(true);
        }
      });
    }
  }, [firm.malpracticeExpired]);

  return (
    <>
      <Grid container direction="column">
        {memos.length > 0 &&
          memos.map(memo => (
            <Grid item key={memo.id}>
              <Typography variant="subtitle1" key={memo.id} className={classes.bannerText}>
                {memo.memoText}{' '}
                <a href={memo.linkUrl} className={classes.linkText}>
                  {memo.linkText}
                </a>
              </Typography>
            </Grid>
          ))}

        {showMalpracticeWarning && (
          <Button className={classes.disabledText} onClick={toggleShowMalpracticeModal}>
            Your malpractice insurance has expired! Please upload new documents here.
          </Button>
        )}

        {firm.hasOfficesThatNeedForcedCheck && firm.showCheckInformationBanner && (
          <>
            <Spacer v={15} />
            <Alert severity="warning">
              <Stack direction="column" alignItems="flex-end" justifyContent="flex-end">
                We require our firms to confirm their data every couple months to be able to use our
                site. Please click here to confirm your data to ensure that we have accurate data to
                share with our members. Note that your profile will still be shown to our members so
                that they can still get in touch with you while you're working to confirm your data,
                not confirming will result in poorer job and client matching.
                <br />
                <Tooltip
                  title={
                    !user.groups.includes(CAN_EDIT_PLF_INFO)
                      ? "You don't have the proper permission to confirm this information"
                      : ''
                  }
                >
                  <span>
                    <Button
                      variant="contained"
                      onClick={toggleShowForceCheckInformationModal}
                      disabled={!user.groups.includes(CAN_EDIT_PLF_INFO)}
                    >
                      Confirm Information
                    </Button>
                  </span>
                </Tooltip>
              </Stack>
            </Alert>
          </>
        )}
      </Grid>

      <ButtonGrid
        title={firm.name}
        bigButtons={ATTORNEY_MENU_ITEMS.filter(
          item =>
            item.style === 'big' &&
            (!item.permissions || user.groups.includes(item.permissions)) &&
            (!firm.disabledByMalpractice || item.showIfMalpracticeExpired) &&
            (firm.newCasesAllowed || !item.hideIfCantTakeNewCases),
        )}
        smallButtons={ATTORNEY_MENU_ITEMS.filter(item => item.style === 'small')}
      />

      <MalpracticeUploadModal
        open={showMalpracticeModal}
        onClose={toggleShowMalpracticeModal}
        afterUploadAction={() => setShowMalpracticeWarning(false)}
        firmId={firm.id}
      />

      {showForceCheckInformationModal && (
        <ForceCheckInformationModal
          firmId={firm.id}
          open={showForceCheckInformationModal}
          onClose={toggleShowForceCheckInformationModal}
        />
      )}
    </>
  );
};

export default observer(AttorneyHome);
