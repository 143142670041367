import PropTypes from 'prop-types';

import { makeStyles } from '@bequestinc/wui';
import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles()({
  root: {
    width: '50%',
    margin: '50px auto',
  },
  skeletonForm: {
    height: 125,
  },
  leftAligned: {
    width: '50%',
    justifyContent: 'flex-start',
  },
});

const Placeholder = ({ alignToLeft }) => {
  const { classes } = useStyles();
  return (
    <div className={alignToLeft ? classes.leftAligned : classes.root}>
      {[1, 2, 3, 4, 5].map(n => (
        <Skeleton key={n} animation="wave" className={classes.skeletonForm} />
      ))}
    </div>
  );
};

Placeholder.propTypes = {
  alignToLeft: PropTypes.bool,
};

Placeholder.defaultProps = {
  alignToLeft: false,
};

export default Placeholder;
